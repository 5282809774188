import { Modal } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { MouseEventHandler, useEffect, useState } from "react";
import SecondaryButton from "../SecondaryButton/SecondaryButton";
import CustomTextField from "../Mui/CustomTextField/CustomTextField";
import { CustomSelect } from "../Mui/CustomSelect/CustomSelect";
import "./CreateOrganization.scss";
import { FormDataError, FormData, OrganizationFunction } from "../../pages/Dashboard/Organization/OrganizationFunction";
import { IOrganization } from "../../helpers/Interfaces";

type CreateOrganizationProps = {
  open: boolean;
  close: MouseEventHandler<HTMLButtonElement> | CallableFunction | any;
  selectedOrganization?: IOrganization;
  refresh: () => void
};

const CreateOrganization = (props: CreateOrganizationProps) => {
  const languages = [{ label: "French", id: 'French' }, { label: "English", id: 'English' }]
  const [formData, setFormData] = useState<FormData>({ name: '', description: '', registeredNumber: '', assetOwner: '', language: '' });
  const [formDataError, setFormDataError] = useState<FormDataError>({ name: false, description: false, registeredNumber: false, assetOwner: false, language: false });
  const [loading, setLoading] = useState<boolean>(false);
  const orgFunction = new OrganizationFunction();

  useEffect(() => {
    console.log(props.selectedOrganization);
    
    if (props.selectedOrganization) {
      setFormData({
        name: props.selectedOrganization.name,
        description: props.selectedOrganization.description,
        registeredNumber: props.selectedOrganization.registeredNumber,
        assetOwner: props.selectedOrganization.assetOwner ?? '',
        language: props.selectedOrganization.language
      })
    }
  }, [props.selectedOrganization])

  const handleFetchData = () => {
    props.refresh();
    props.close();
    setFormData({ name: '', description: '', registeredNumber: '', assetOwner: '', language: '' })
    setFormDataError({ name: false, description: false, registeredNumber: false, assetOwner: false, language: false })
  }

  return (
    <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="insert-image-container">
        <div className="header-outer">
          <span className="header-title">{props.selectedOrganization?.id ? "Update" : "Create"} Organization</span>
          <button onClick={() => {
            props.close();
          }}><CloseSharp /></button>
        </div>
        <div className="form-content">
          <CustomTextField value={formData.name} error={formDataError.name} required size="small" label="Name" onChange={(val) => {
            if (!val) return;
            setFormData({ ...formData, name: val.target.value });
          }} />
          <CustomTextField value={formData.registeredNumber} error={formDataError.registeredNumber} required size="small" label="Registration Number" onChange={(val) => {
            if (!val) return;
            setFormData({ ...formData, registeredNumber: val.target.value });
          }} />
          <CustomSelect defaultValue={{ label: formData.language ?? '', id: formData.language ?? '' }} error={formDataError.language} require label="Select Language" options={languages} onSelect={(val) => {
            if (!val) return;
            setFormData({ ...formData, language: val.label });
          }} />
          <CustomTextField value={formData.description} error={formDataError.description} required multiline size="small" label="Description" onChange={(val) => {
            if (!val) return;
            setFormData({ ...formData, description: val.target.value });
          }} />
        </div>
        <div className="footer-outer">
          <SecondaryButton loading={loading} onClick={orgFunction.createOrganization(formData, formDataError, setFormDataError, setLoading, handleFetchData, props.selectedOrganization?.id)}
            label={props.selectedOrganization?.id ? "Update" : "Create"} />
        </div>
      </div>
    </Modal>
  );
};

export default CreateOrganization;
