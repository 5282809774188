import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordFunctions from './forgotPasswordFunctions';
import { Link, useNavigate } from 'react-router-dom';
import OtpVerificationForm from './OtpVerificationForm';
import PasswordResetForm from './PasswordResetForm';
import AccordionDownIcon from '../../assets/images/AccordionDownIcon';
import EditSquare from '../../assets/images/EditSquare';

const ForgotPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const forgotPasswordFunctions = new ForgotPasswordFunctions();
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [forgetPasswordState, setForgetPasswordState] = useState<{ state: 'verifyEmail' | 'addOtp' | 'confirmPassword' }>({ state: 'verifyEmail' });
    const [customToken, setCustomToken] = useState<string>();
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    useEffect(() => {
    }, [customToken])

    const handleForgotPassword = (email: string) => {
        setEmail(email);
        const payload = { email };

        forgotPasswordFunctions.sendOtp(
            payload,
            setLoading,
            (response: any) => {
                setForgetPasswordState({ state: 'addOtp' });
            },
            (error: any) => {
                console.error("Error sending reset password email ", error);
            }
        )
    };

    const handleSubmitNewPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            return;
        }
        setLoading(true);
        try {
            await handleResetPassword(newPassword);
        } catch (error) {
            console.error("Password reset failed", error);
        } finally {
            setLoading(false);
        }
    };

    const handleOtpSubmit = (otp: string) => {
        setLoading(true);
        const payload = { email, otp };

        forgotPasswordFunctions.verifyOtp(
            payload,
            setLoading,
            (response: any) => {
                setCustomToken(response.customToken)
                setForgetPasswordState({ state: 'confirmPassword' });
            },
            (error: any) => {
                console.error("Error verifying otp", error);
            }
        );
    };

    const handleResetPassword = async (newPassword: string): Promise<void> => {
        const payload = { customToken, password: newPassword };

        return new Promise((resolve, reject) => {
            forgotPasswordFunctions.resetPassword(
                payload,
                setLoading,
                (response: any) => {
                    resolve(response);
                    navigate('/login');
                },
                (error: any) => {
                    console.error("Error resetting password", error);
                    reject(error);
                }
            );
        });
    };

    const AuthLayoutProps = () => {
        switch (forgetPasswordState.state) {
            case "verifyEmail":
                return { title: t('forgotPasswordHeader'), description: t('forgotPasswordDescription') };
            case "addOtp":
                return { title: t('otpVerificationHeader'), description: t('otpVerificationDescription'), email: email, element: <div onClick={() => { setForgetPasswordState({ state: 'verifyEmail' }); }}><EditSquare /></div> };
            case "confirmPassword":
                return { title: t('passwordResetHeader'), description: t('passwordResetDescription') };
            default:
                return { title: '', description: '' };
        }
    };

    const getUi = () => {
        switch (forgetPasswordState.state) {
            case "verifyEmail":
                return (<ForgotPasswordForm defaultEmail={email} handleForgotPassword={handleForgotPassword} loading={loading} />);
            case "addOtp":
                return (<OtpVerificationForm email={email} onOtpSubmit={handleOtpSubmit} loading={loading} />);
            case "confirmPassword":
                return (<PasswordResetForm
                    newPassword={newPassword}
                    confirmPassword={confirmPassword}
                    loading={loading}
                    onChangeNewPassword={(e) => setNewPassword(e.target.value)}
                    onChangeConfirmPassword={(e) => setConfirmPassword(e.target.value)}
                    onSubmit={handleSubmitNewPassword}
                />);;
        }
    }

    return (
        <AuthLayout contentData={AuthLayoutProps()}>
            {getUi()}
        </AuthLayout>
    )
};

export default ForgotPassword;