import AuthService from '../../services/AuthService'
import ApiService from '../../services/ApiService'

export default class LoginFunctions {
    authService = new AuthService();
    apiService = new ApiService();

    userLogin = (data: any, setLoading: CallableFunction, setUser: CallableFunction) => {
        setLoading(true);
        this.authService.userLogin(data).then((response) => {
            setUser(response.data.user);
        }).catch(() => {

        }).finally(() => {
            setLoading(false);
        })
    }
}