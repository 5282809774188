import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrganization } from "../../helpers/Interfaces";

const initialState: IOrganization[] = [];

export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        updateOrganizations: (state, action: PayloadAction<IOrganization[]>) => {
            return action.payload;
        }
    }
});

export const { updateOrganizations } = organizationsSlice.actions;
export default organizationsSlice.reducer;