import { CircularProgress, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import TextIcon from "../../../assets/images/builder/text_black.svg";
import OrientationIcon from "../../../assets/images/builder/orientation_black.svg";
import { Close } from "@mui/icons-material";
import { CustomSelect } from "../../Mui/CustomSelect/CustomSelect";
import { templateCategory } from "../../../helpers/constants";
import CustomTextField from "../../Mui/CustomTextField/CustomTextField";
import BuilderFunctions from "../../../pages/Dashboard/Builder/BuilderFunctions";
import { ItineraryDocument } from "../../../helpers/Interfaces";
import { generateUniqueKey } from "../../../helpers/helperfunctions";
import { StoreType } from "polotno/model/store";
import "./CreateItineraryPage.scss";
import FirebaseFunctions from "../../../helpers/firebaseFunctions";

type CreateItineraryPageProps = {
  open: boolean;
  close: CallableFunction;
  store: StoreType
};

const CreateItineraryPage = (props: CreateItineraryPageProps) => {
  const [formData, setFormData] = useState<ItineraryDocument>({ documentType: 'page', documentId: '', documentContent: '' });
  const [formDataError, setFormDataError] = useState<{ documentName?: boolean, documentCategory?: boolean }>();
  const [loading, setLoading] = useState<boolean>(false);
  const builderFunctions = new BuilderFunctions()

  const onSubmit = async () => {
    if (!formData?.documentName) {
      setFormDataError({ ...formDataError, documentName: true });
      return;
    } else {
      setFormDataError({ documentCategory: false, documentName: false })
    }

    if (!formData?.documentCategory) {
      setFormDataError({ ...formDataError, documentCategory: true });
      return;
    } else {
      setFormDataError({ documentCategory: false, documentName: false })
    }

    setLoading(true)

    try {
      await builderFunctions.savePage(props.store, formData, generateUniqueKey());
      setLoading(false)
      close()

    } catch (e) {
      setLoading(false)
    }
  }

  const close = () => {
    setFormData({ documentType: 'page', documentId: '', documentContent: '' });
    setFormDataError({})
    props.close();
  }

  return (
    <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="new-itinerary-page-container">
        <span className="header-title">
          Create New Page
          <div className="close-outer">
            <button onClick={() => close()}>
              <Close />
            </button>
          </div>
        </span>

        <div className="create-page-outer">
          <div className="section-outer">
            <div className="section-header">
              <img src={TextIcon} alt="Text Icon" />
              <span>Page Name</span>
            </div>
            <CustomTextField error={formDataError?.documentName} size="small" value={formData?.documentName} onChange={(val: any) => setFormData({ ...formData, documentName: val.target.value })} placeholder="New Page for Hugo Mille #4" />
          </div>
          <div className="section-outer">
            <div className="section-header">
              <img src={OrientationIcon} alt="Text Icon" />
              <span>Page Category</span>
            </div>
            <CustomSelect error={formDataError?.documentCategory} label={""} options={templateCategory} onSelect={(val) => setFormData({ ...formData, documentCategory: val.label })} />
          </div>
          {/* <div className="section-outer">
            <div className="section-header">
              <img src={PageSizeIcon} alt="Text Icon" />
              <span>Tags (Optional)</span>
            </div>
            <CustomMultiSelect options={tags} label={''} onSelect={() => { }} />
          </div> */}
        </div>
        <button onClick={onSubmit} className="initializer-button">{loading ? <CircularProgress size={14} color="inherit" /> : "Create Page"}</button>
      </div>
    </Modal>
  );
};

export default CreateItineraryPage;
