import dayjs from 'dayjs';
import { DropdownItemProps } from "../components/Mui/CustomAutocomplete/CustomAutocomplete";
import { ILeadStageLocalization, IUser } from "./Interfaces";
import { t } from 'i18next';
import { compressToBase64, decompressFromBase64 } from 'lz-string';

export const isProduction = () => {
    // return (process.env.NODE_ENV === 'production')
    return true;
}

export const getBaseURL = () => {
    if (isProduction()) {
        return "https://app-jvo4boqwja-nw.a.run.app"
        // return "https://us-central1-explor-app.cloudfunctions.net/app"
    } else {
        return "https://app-dscz5fxuja-uc.a.run.app"
        // return "https://app-v4qyh3l3aa-uc.a.run.app"
    }
}

export const googleApiKey = () => {
    return process.env.GOOGLE_API_KEY ?? 'AIzaSyDscgvnthryp-Ll8A5PwpluyYJHafbovjQ';
}

export const mapBoxApiKey = () => {
    return process.env.GOOGLE_API_KEY ?? 'pk.eyJ1IjoiZXhwbG9yLWFwcCIsImEiOiJjbTJ2ZWZmcTgwYjNwMmpvZ2p6eWhtMTM4In0.9p_WxTZknC4Dv75c4fgIGg';
}

// user authentication functions : end

export function isEmptyOrNullObj(obj: object | undefined | null) {
    if (obj === null || obj === undefined) {
        return true;
    }
    return Object.keys(obj).length === 0;
}

export const getDefaultValueInDropdown = (array: DropdownItemProps[], value?: string): DropdownItemProps | undefined => {
    if (!value) return undefined;
    const newArray = array.map(item => ({ ...item, label: t(item.label) }))
    return newArray.find(T => T.label === value) ?? { id: '', label: value } as DropdownItemProps;
}

export const getMultiSelectDefaultValues = (array: DropdownItemProps[], values?: string[]): DropdownItemProps[] | undefined => {
    if (!values) return undefined;
    const list = array.filter(item => values.includes(t(item.label)));
    return list;
}

export const getMultiSelectedItemsString = (array: DropdownItemProps[]): string[] => {
    return array.map(item => item.label);
}

export const formatDate = (day?: any, format: string = 'YYYY-MM-DD') => {
    if (!day) {
        return '-';
    }

    if (day?._seconds) {
        const date = new Date(day?._seconds * 1000);
        return dayjs(date).format(format);
    } else {
        return dayjs(day).format(format);
    }
}

export const formatString = (string?: string) => {
    if (!string)
        return '-';

    return string;
}

export const getMultiSelectedItemsView = (values?: string[]): string => {
    if (!values)
        return '-';

    return values.join(' / ');
}

export const generateUniqueKey = (length: number = 20) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
}

export const formatNumber = (value?: string) => {
    if (!value) return '0';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const validatePhoneNumber = (phoneNumber?: string) => {
    if (!phoneNumber) return true;

    const internationalPhoneRegex = /^\+\d{1,3}\d{4,15}$/;
    return internationalPhoneRegex.test(phoneNumber);
};

export const validateEmail = (email?: string) => {
    if (!email) return true;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const validateNumber = (value?: string) => {
    if (!value) return true;
    const numberRegex = /^[0-9]+$/;
    return numberRegex.test(value);
};

export const getPortShortName = (port?: string) => {
    if (!port) return ('-')
    const splittedPort = port.split(' ');
    return `${splittedPort[splittedPort.length - 1]}`;
}


export const getStageColor = (stage: string) => {
    if (stage === 'PENDING') {
        return ('--c-gray');
    } else if (stage === 'IN-PROGRESS') {
        return ('--c-light-green')
    }
    return ('--c-dark-green')
}

export function getMonthName(monthNumber: number): string {
    const months: string[] = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
        return months[monthNumber - 1];
    } else {
        return "Invalid month number";
    }
}

function hashCode(str: string) {
    let hash = 0;
    if (str) {
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash; // Convert to 32bit integer
        }
    }
    return hash;
}

function getColorComponent(value: number, min: number, max: number) {
    const range = max - min;
    return ((value % range) + range) % range + min;
}

function darkenColorComponent(value: number, factor: number) {
    return Math.max(0, Math.min(255, Math.floor(value * factor)));
}

export function getUniqueColor(id: string) {
    const hash = hashCode(id);

    const min = 100;
    const max = 255;

    const r = getColorComponent(hash, min, max);
    const g = getColorComponent(Math.floor(hash / 7), min, max);
    const b = getColorComponent(Math.floor(hash / 17), min, max);

    const backgroundColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

    const darkFactor = 0.6; // Adjust this factor to make the text color darker
    const darkR = darkenColorComponent(r, darkFactor);
    const darkG = darkenColorComponent(g, darkFactor);
    const darkB = darkenColorComponent(b, darkFactor);

    const textColor = `#${darkR.toString(16).padStart(2, '0')}${darkG.toString(16).padStart(2, '0')}${darkB.toString(16).padStart(2, '0')}`;

    return { backgroundColor, textColor };
}

export const stringAvatar = (name: string, fontSize?: string, size?: number) => {
    const color = getUniqueColor(name);
    const stringArray = name.split(' ');
    const first = stringArray[0][0];
    const second = stringArray.length >= 2 ? stringArray[1][0] : '';

    return {
        sx: {
            bgcolor: color.backgroundColor,
            color: color.textColor,
            fontSize: fontSize ?? '12px',
            width: size ?? 24, height: size ?? 24
        },
        children: first + second,
    };
}

export const capitalizeFirstLetter = (description: string): string => {
    return description.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

export const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        }
    });

export const downloadPdf = async (url: string, filename: string, setLoading: CallableFunction) => {
    try {
        setLoading(true);
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error("Failed to download the file.");
        }

        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);

    } catch (error) {
        setLoading(false);
        console.error("Error downloading the PDF:", error);
    }
};

export function replaceUndefinedWithNull(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(replaceUndefinedWithNull); // Recursively handle arrays
    } else if (obj && typeof obj === 'object') {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [
                key,
                value === undefined ? null : replaceUndefinedWithNull(value), // Replace undefined with null
            ])
        );
    }
    return obj; // Return the original value if it's neither an array nor an object
}
export function bytesToMegabytes(bytes: number): any {
    return parseFloat((bytes / (1024 * 1024)).toFixed(2));
};
export function getFileExtension(name: string): string {
    return name.split('.').pop() || '';
};



// Compress data using zlib
export function compressData(data: any): string {
    const jsonString = JSON.stringify(data);
    return compressToBase64(jsonString);
}

// Decompress data using zlib
export const decompressData = (compressed: string): any => {
    try {
        const decompressed = decompressFromBase64(compressed);
        return decompressed ? JSON.parse(decompressed) : null;
    } catch (e) {
        return compressed;
    }
};
