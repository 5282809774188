import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IListUser, IOrganization } from "../../helpers/Interfaces";

const initialState: IListUser[] = [];

export const userListSlice = createSlice({
    name: 'userList',
    initialState,
    reducers: {
        updateUserList: (state, action: PayloadAction<IListUser[]>) => {
            return action.payload;
        }
    }
});

export const { updateUserList } = userListSlice.actions;
export default userListSlice.reducer;