import axios from "axios";
import { mapBoxApiKey } from "../../../../../helpers/helperfunctions";
import { LatLng } from "../../../../../helpers/Interfaces";
import { MutableRefObject } from "react";
import ApiService from "../../../../../services/ApiService";

export class BuilderMapFunctions {
    apiService = new ApiService();
    getMapImage = async ({ center, zoom, size, encodedPath, pathCoordinates, apiKey }:
        {
            center: { lat: number; lng: number },
            zoom: number,
            size: string,
            encodedPath: any,
            pathCoordinates?: { lat: number; lng: number }[],
            apiKey?: string
        }) => {
        try {
            const baseUrl = "https://maps.googleapis.com/maps/api/staticmap";

            const centerParam = `center=${center.lat},${center.lng}`;
            const zoomParam = `zoom=${zoom}`;
            const sizeParam = `size=${size}`;
            const keyParam = `key=${apiKey}`;

            let pathParam = `&path=color:0x134B5F|weight:5|enc:${encodedPath}`;

            let scaleParam = `scale=2`

            const markerIconUrl = "https://explor-dev.web.app/marker.png";
            const markerParams = pathCoordinates
                ? pathCoordinates
                    .map(coord =>
                        `markers=${encodeURIComponent(`icon:${markerIconUrl}|${coord.lat},${coord.lng}`)}`
                    )
                    .join("&")
                : "";

            const url = `${baseUrl}?${centerParam}&${zoomParam}&${sizeParam}${pathParam}&${markerParams}&${scaleParam}&${keyParam}`;

            const response = await this.apiService.getItineraryImage({ url })

            return response.data;

        } catch (error) {
            alert('Error fetching route or map image')
            console.error("Error fetching route or map image:", error);
        }
    };


    calculateRoute = ({ directionsServiceRef, directionsRendererRef, customMarkersRef, mapRef, destinations, setMapUrl }:
        {
            directionsServiceRef: MutableRefObject<google.maps.DirectionsService | null>,
            directionsRendererRef: MutableRefObject<google.maps.DirectionsRenderer | null>,
            customMarkersRef: MutableRefObject<google.maps.Marker[]>,
            destinations: LatLng[],
            setMapUrl: CallableFunction,
            mapRef: MutableRefObject<google.maps.Map | null>
        }) => {
        if (!directionsServiceRef.current || !directionsRendererRef.current) return;
        directionsRendererRef.current.setDirections(null);

        const waypoints = destinations.slice(1, -1).map(location => ({
            location: new google.maps.LatLng(location.lat, location.lng),
            stopover: true,
        }));

        const origin = destinations[0];
        const destination = destinations[destinations.length - 1];

        directionsServiceRef.current.route(
            {
                origin: new google.maps.LatLng(origin.lat, origin.lng),
                destination: new google.maps.LatLng(destination.lat, destination.lng),
                waypoints,
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (!result) return;

                const encodedPath = result.routes[0].overview_polyline;

                setMapUrl(encodedPath);

                if (status === google.maps.DirectionsStatus.OK) {
                    directionsRendererRef.current?.setDirections(result);
                    this.addCustomMarkers({ directionsResult: result as google.maps.DirectionsResult, customMarkersRef, mapRef });
                } else {
                    console.error("Could not display directions due to: " + status);
                }
            }
        );
    };

    addCustomMarkers = ({ directionsResult, customMarkersRef, mapRef }:
        {
            directionsResult: google.maps.DirectionsResult,
            customMarkersRef: MutableRefObject<google.maps.Marker[]>,
            mapRef: MutableRefObject<google.maps.Map | null>
        }
    ) => {
        // Clear any existing custom markers
        customMarkersRef.current.forEach(marker => marker.setMap(null));
        customMarkersRef.current = [];

        // Add custom markers for each route leg start and end
        const route = directionsResult.routes[0];

        // Custom marker at the origin
        const originMarker = new google.maps.Marker({
            position: route.legs[0].start_location,
            map: mapRef.current,
            icon: {
                url: "https://explor-dev.web.app/marker.svg", // Replace with your custom icon URL
                scaledSize: new google.maps.Size(32, 32),
            },
            title: "Start",
        });
        customMarkersRef.current.push(originMarker);

        // Add custom markers for waypoints
        route.legs.forEach((leg, index) => {
            const waypointMarker = new google.maps.Marker({
                position: leg.end_location,
                map: mapRef.current,
                icon: {
                    url: "https://explor-dev.web.app/marker.svg", // Replace with your custom icon URL
                    scaledSize: new google.maps.Size(28, 28),
                },
                title: `Waypoint ${index + 1}`,
            });
            customMarkersRef.current.push(waypointMarker);
        });

        // Custom marker at the final destination
        const destinationMarker = new google.maps.Marker({
            position: route.legs[route.legs.length - 1].end_location,
            map: mapRef.current,
            icon: {
                url: "https://explor-dev.web.app/marker.svg", // Replace with your custom icon URL
                scaledSize: new google.maps.Size(32, 32),
            },
            title: "End",
        });
        customMarkersRef.current.push(destinationMarker);
    };
}
