
import "./Login.scss";
import { useEffect, useState } from "react";
import { ILoginData, ILoginResponse, IUser } from "../../helpers/Interfaces";
import Functions from './loginFunctions'
import { CircularProgress } from "@mui/material";
import { setUser } from '../../store/slices/userSlice';
import { AppDispatch } from '../../store/store';
import { useDispatch } from "react-redux";
import { storeLocalStorage, saveTokens, saveUser } from "../../helpers/storageConfig";
import { useNavigate } from "react-router-dom";
import { DropdownItemProps } from "../../components/Mui/CustomAutocomplete/CustomAutocomplete";
import { useTranslation } from "react-i18next";
import '../../i18n/config';
import FirebaseFunctions from "../../helpers/firebaseFunctions";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import { Link } from "react-router-dom";
import userImg from "../../assets/images/user.svg"
import lockImg from "../../assets/images/lock.svg"
import eyeImg from "../../assets/images/eye.svg"
import eyeClose from "../../assets/images/eyeClose.svg"

const Login = () => {
  const { t, i18n } = useTranslation();
  const functions = new Functions();
  const firebaseFunctions = new FirebaseFunctions();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState<ILoginData>({ email: '', password: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  useEffect(() => {
    i18n.changeLanguage(navigator.language)
  }, [])

  const handleLogin = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    const data = {
      email: loginForm.email,
      password: loginForm.password,
    }

    functions.userLogin(data, setLoading, (response: ILoginResponse) => {
      firebaseFunctions.authenticateUser(response.firebaseCustomToken).then(() => {
        const user: IUser = {
          email: response.email,
          displayName: response.name,
          photoUrl: response.photoUrl,
          tenantId: response.tenant.id,
        }

        saveTokens(response.token);
        storeLocalStorage('refreshToken', response.uid);
        storeLocalStorage('firebaseCustomToken', response.firebaseCustomToken);
        storeLocalStorage('tenantId', response.tenant.id);
        saveUser(user);
        dispatch(setUser(user));
        navigate('/dashboard/library-templates');
      });
    });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <AuthLayout contentData={{ title: "Sign in to admin portal", description: t('loginDescription') }}>
      <form onSubmit={handleLogin}
        className="login-form">
        <div className="form-group">
          <input required type="text" className="form-control" placeholder="Username or Email Address" onChange={(data) => {
            let newForm = loginForm;
            newForm.email = data.target.value;
            setLoginForm({ ...newForm });
          }} />
        </div>
        <div className="form-group">
          <input required type={showPassword ? 'text' : 'password'} className="form-control" placeholder="Password" onChange={(data) => {
            let newForm = loginForm;
            newForm.password = data.target.value;
            setLoginForm({ ...newForm });
          }} />
          <button onClick={togglePasswordVisibility} className="password-toggle" type="button"><img src={showPassword ? eyeClose : eyeImg} alt="" /></button>
        </div>
        <div className="sm-msg primary-font">
          <span className="inner"><img src={lockImg} alt="lock" /><span className="txt"><Link to="/forgot-password" style={{ color: 'inherit', textDecoration: 'none' }} > Forgot Password? </Link> </span></span>
        </div>
        <button type="submit" className="submit fz-16 primary-font fw-600 ttu c-white">{loading
          ? (<CircularProgress color="inherit" size={28} />)
          : ("Login")}</button>
        <div className="sm-msg primary-font">
          <span className="inner"><img src={userImg} alt="lock" /><span className="txt">Don’t have an account? <span className="c-orange">Talk to Support</span></span></span>
        </div>
      </form>
    </AuthLayout>
  )
}

export default Login

