import { CircularProgress, Modal } from "@mui/material";
import "./CreateTemplate.scss";
import { useContext, useState } from "react";
import TextIcon from "../../assets/images/builder/text_black.svg";
import OrientationIcon from "../../assets/images/builder/orientation_black.svg";
import { Close } from "@mui/icons-material";
import CustomTextField from "../Mui/CustomTextField/CustomTextField";
import { emptyTemplate, templateCategory } from "../../helpers/constants";
import { CustomSelect } from "../Mui/CustomSelect/CustomSelect";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { setItinerary } from "../../store/slices/itinerarySlice";
import { compressData, generateUniqueKey } from "../../helpers/helperfunctions";
import { ItineraryDocument } from "../../helpers/Interfaces";
import ApiService from "../../services/ApiService";
import { Store } from "../../store/polontoStore";

type CreateTemplateProps = {
  open: boolean;
  close: CallableFunction;
  itinerary?: ItineraryDocument
};

const CreateTemplate = (props: CreateTemplateProps) => {
  const [formData, setFormData] = useState<{ name?: string, category?: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [formDataError, setFormDataError] = useState<{ name?: boolean, category?: boolean }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [_, loadJson] = useContext(Store);

  const apiService = new ApiService();

  const onSubmit = async () => {
    if (!formData?.name) {
      setFormDataError({ ...formDataError, name: true });
      return;
    } else {
      setFormDataError({ category: false, name: false })
    }

    if (!formData?.category) {
      setFormDataError({ ...formDataError, category: true });
      return;
    } else {
      setFormDataError({ category: false, name: false })
    }

    const document: any = {
      documentType: 'template',
      documentContent: compressData(emptyTemplate),
      documentCategory: formData?.category,
      documentName: formData?.name,
    };

    setLoading(true);

    await apiService.createTemplate({ template: document })
      .then((response) => {
        document.documentId = response.data.templateId;
        loadJson(emptyTemplate);
        dispatch(setItinerary(document));
        navigate('/dashboard/builder');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const close = () => {
    setFormData({});
    setFormDataError({})
    props.close();
  }

  return (
    <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="new-table-page-container">
        <span className="header-title">
          Create New Template
          <div className="close-outer">
            <button onClick={close}>
              <Close />
            </button>
          </div>
        </span>

        <div className="create-page-outer">
          <div className="section-outer">
            <div className="section-header">
              <img src={TextIcon} alt="Text Icon" />
              <span>Template Name</span>
            </div>
            <CustomTextField error={formDataError?.name} size="small" value={formData?.name} onChange={(val: any) => setFormData({ ...formData, name: val.target.value })}
              placeholder="Place Your Template Name" />
          </div>
          <div className="section-outer">
            <div className="section-header">
              <img src={OrientationIcon} alt="Text Icon" />
              <span>Template Category</span>
            </div>
            <CustomSelect error={formDataError?.category} label={""} options={templateCategory} onSelect={(val) => setFormData({ ...formData, category: val.label })} />
          </div>
        </div>
        <button onClick={onSubmit} className="initializer-button">
          {loading ? (<CircularProgress size={16} color='inherit' />) : "Create Template"}</button>
      </div>
    </Modal>
  );
};

export default CreateTemplate;
