import { useContext, useEffect, useState } from 'react';
import Dashboard from '../../../components/Dashboard/Dashboard'
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno/polotno-app';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import {
    SectionTab, SidePanel, TextSection, UploadSection, PagesSection,
    SizeSection, BackgroundSection, PhotosSection, LayersSection, ElementsSection, DEFAULT_SECTIONS
} from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace'
import { StoreType } from 'polotno/model/store';
import { MapOutlined, SpaceDashboardOutlined } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import PdfPreview from '../../../components/Builder/PdfPreview/PdfPreview';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { changeSideBar } from '../../../store/slices/sideBarSlice';
import { initialItineraryState, setItinerary } from '../../../store/slices/itinerarySlice';
import SaveIcon from '../../../assets/images/builder/SaveIcon';
import CancelIcon from '../../../assets/images/builder/Cancel';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from '../../../components/SecondaryButton/SecondaryButton';
import { ItineraryDocument } from '../../../helpers/Interfaces';
import { Store } from '../../../store/polontoStore';
import { emptyTemplate } from '../../../helpers/constants';
import { PageControls } from './Components/Page/PageControls';
import CreateItineraryPage from '../../../components/Builder/CreateItineraryPage/CreateItineraryPage';
import BuilderFunctions from './BuilderFunctions';
import '@blueprintjs/core/lib/css/blueprint.css';
import "./Builder.scss"
import BuilderTemplates from './Components/Libraries/BuilderTemplates';
import BuilderPages from './Components/Libraries/BuilderPages';
import BuilderMap from './Components/Map/BuilderMap';
import { IconsSection } from './Components/Icons/BuilderIcon';
import { QrSection } from './Components/qrCode/BuilderQrCode';

const Builder = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState<{ submit: boolean, save: boolean }>({ submit: false, save: false });
    const navigate = useNavigate();
    const builderFunction = new BuilderFunctions();
    const [pdfViewer, setPdfViewer] = useState<{ open: boolean, url: string | undefined }>({ open: false, url: '' });
    const [createPage, setCreatePage] = useState<boolean>(Boolean);
    const itinerary: ItineraryDocument = useSelector((state: RootState) => state.itinerary);
    const [store] = useContext(Store) as [StoreType];
    const [_, loadJson] = useContext(Store);
    const [value, setValue] = useState(0);

    useEffect(() => {
        dispatch(changeSideBar(true))
        
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const CustomMap = {
        name: 'Map',
        Tab: (props: any) => (
            <SectionTab name="Map" {...props}>
                <MapOutlined />
            </SectionTab>
        ),
        Panel: observer(({ store }: any) => {
            return (
                <div style={{ height: '100%' }}>
                    <BuilderMap store={store} />
                </div>
            );
        }),
    };

    const CustomTemplate = {
        name: 'Library',
        Tab: (props: any) => (
            <SectionTab name="Library" {...props}>
                <SpaceDashboardOutlined />
            </SectionTab>
        ),
        Panel: observer(({ store }: any) => {
            return (
                <div style={{ height: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Templates" {...builderFunction.a11yProps(0)} />
                            <Tab label="Pages" {...builderFunction.a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <builderFunction.CustomTabPanel value={value} index={0}>
                        <BuilderTemplates store={store} />
                    </builderFunction.CustomTabPanel>
                    <builderFunction.CustomTabPanel value={value} index={1}>
                        <BuilderPages store={store} />
                    </builderFunction.CustomTabPanel>
                </div>
            );
        }),
    };

    const customSidebar = [LayersSection, SizeSection,ElementsSection, IconsSection,  PhotosSection, TextSection, UploadSection, PagesSection, BackgroundSection, CustomTemplate, CustomMap, QrSection];

    const handleSavePage = () => setCreatePage(true);

    const handleCancelDoc = () => {
        loadJson(emptyTemplate);
        dispatch(setItinerary(initialItineraryState))
        navigate('/dashboard/library-templates');
    }

    const saveDoc = (publish: boolean) => async () => {
        if (itinerary.documentType === 'page') {
            setLoading({ ...loading, save: true });
            await builderFunction.savePage(store, itinerary, itinerary.documentId ?? '');
            setLoading({ ...loading, save: false });
            handleCancelDoc();
        } else {
            builderFunction.handleSaveDoc({ setLoading, loading, store, loadJson, itinerary, pdfViewer, publish })
        }
    }

    const ActionControls = ({ store }: any) => {
        return (
            <Stack direction='row' spacing={1}>
                <button onClick={handleCancelDoc}>
                    <CancelIcon />
                </button>
                <SaveIcon loading={loading.save} onClick={saveDoc(false)} />
                {/* {itinerary.documentType === 'itinerary' ? (<SecondaryButton style={{ height: '35px', padding: '0px 5px' }} loading={loading.submit} label="Save as Template" onClick={() => { }} />) : (<></>)} */}
                {itinerary.documentType !== 'page' ? (<SecondaryButton style={{ height: '35px', padding: '0px 5px' }} loading={loading.submit}
                    label="Publish" onClick={builderFunction.handlePublish({ itinerary, setPdfViewer, setLoading, loading, store })} />) : (<></>)}
            </Stack>
        );
    };

    return (
        <Dashboard headerTitle={"Itinerary"}>
            <div style={builderFunction.builderVisibility(itinerary)} className="builder-container">
                <PolotnoContainer style={{ maxHeight: "calc(100vh - 129px)" }}>
                    <SidePanelWrap>
                        <SidePanel store={store} sections={customSidebar} defaultSection='SizeSection' />
                    </SidePanelWrap>
                    <WorkspaceWrap>
                        <Toolbar store={store} components={{ ActionControls }} />
                        <Workspace
                            store={store}
                            components={{
                                PageControls: ({ store, page, xPadding, yPadding }: any) =>
                                    <PageControls store={store} page={page} xPadding={xPadding} yPadding={yPadding}
                                        createPage={handleSavePage} documentType={itinerary.documentType} />
                            }}
                        />
                        {/* <Workspace store={store} /> */}
                        <ZoomButtons store={store} />
                    </WorkspaceWrap>
                    <CreateItineraryPage open={createPage} close={() => setCreatePage(false)} store={store} />
                    <PdfPreview open={pdfViewer.open} close={() => setPdfViewer({ open: false, url: undefined })} pdfLink={pdfViewer.url ?? ''} itineraryName={itinerary.documentName ?? ''}
                        publish={saveDoc(true)} />
                </PolotnoContainer>
            </div>
        </Dashboard>
    )
}

export default Builder;
