import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import sideBarReducer from "./slices/sideBarSlice";
import itineraryIdReducer from "./slices/itinerarySlice";
import organizationReducer from "./slices/organizationsSlice";
import userListReducer from "./slices/listUeresSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        sideBar: sideBarReducer,
        itinerary: itineraryIdReducer,
        organizations: organizationReducer,
        userList: userListReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;