import { useEffect, useState } from 'react'
import ApiService from '../../../../../services/ApiService'
import { ItineraryDocument } from '../../../../../helpers/Interfaces';
import BuilderFunctions from '../../BuilderFunctions';
import { StoreType } from 'polotno/model/store';
import { Box, Skeleton } from '@mui/material';

const BuilderPages = ({ store }: { store: StoreType }) => {
    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState<ItineraryDocument[]>([]);
    const apiService = new ApiService();
    const builderFunction = new BuilderFunctions();

    useEffect(() => {
        getAllTemplates();
    }, [])

    const getAllTemplates = () => {
        setLoading(true);
        apiService.getItineraryPages({ contentRequired: true }).then((response) => {
            setTemplates(response.data.data)
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getPageView = () => {
        if (loading) {
            return (Array.from({ length: 5 }, (_, index) => (
                <Box minWidth={'130px'} >
                    <Skeleton variant="rounded" height={'130px'} sx={{ borderRadius: '10px' }} />
                </Box>
            )))
        } else if (templates.length === 0) {
            <>No Templates</>
        } else {
            return (templates.map((template) => (
                <button onClick={builderFunction.addDocument({ store, itinerary: template })}>
                    <img src={template.previewUrl} />
                </button>)))
        }
    }

    return (
        <div className='template-outer'>
            {getPageView()}
        </div>
    )
}

export default BuilderPages