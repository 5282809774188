import { useCallback, useEffect, useState } from 'react'
import "./Organization.scss"
import SecondaryButton from '../../../components/SecondaryButton/SecondaryButton';
import PluseSvg from '../../../assets/images/buttons/PluseSvg';
import Dashboard from '../../../components/Dashboard/Dashboard';
import CreateOrganization from '../../../components/CreateOrganization/CreateOrganization';
import { IOrganization } from '../../../helpers/Interfaces';
import { OrganizationFunction } from './OrganizationFunction';
import Paper from '@mui/material/Paper';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Menu, MenuItem } from '@mui/material';
import { formatDate } from '../../../helpers/helperfunctions';
import { MoreVertOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';

const Organization = () => {
    const [view, setView] = useState<'organization' | 'user'>('organization');
    const [createOrg, setCreateOrg] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const functions = new OrganizationFunction();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedOrganization, setSelectedOrganization] = useState<IOrganization>();
    const organizations = useSelector((state: RootState) => state.organizations);
    const dispatch = useDispatch<AppDispatch>();

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, selectedOrg: IOrganization) => {
        setSelectedOrganization({ ...selectedOrg, name: selectedOrg.name });
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getOrganizations = useCallback(
        () => functions.getAllOrganizations(dispatch, setLoading), [],)

    const handleCreateOrg = () => {
        handleClose();
        setCreateOrg((prev: boolean) => !prev);
    }

    return (
        <Dashboard headerTitle={"Organization"}>
            <div className='organization-container'>
                <div className="content-top">
                    <div className="lft">

                    </div>
                    <div className="rht">
                        <div className="content-right">
                            {/* <div className="filter-outer">
                                <span>
                                    {false ? <UserFilterRemove /> : <UserFilter />}
                                </span>
                                <span>
                                    {false ? <FilterIconRemove /> : <FilterIcon />}
                                </span>
                            </div> */}
                            <SecondaryButton label="Add New" icon={<PluseSvg />} onClick={handleCreateOrg} />
                        </div>
                    </div>
                </div>
                <TableContainer sx={{ marginTop: 3 }} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell align="right">Name</TableCell>
                                <TableCell align="right">Language</TableCell>
                                {/* <TableCell align="right">Registered Number</TableCell> */}
                                <TableCell align="right">Created Date</TableCell>
                                {/* <TableCell size='small' align="right"></TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {organizations.map((organization) => (
                                <TableRow
                                    key={organization.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {organization.id}
                                    </TableCell>
                                    <TableCell align="right">{organization.name}</TableCell>
                                    <TableCell align="right">{organization.language ? organization.language : '-'}</TableCell>
                                    {/* <TableCell align="right">{organization.registeredNumber}</TableCell> */}
                                    <TableCell align="right">{organization.createdDate ? formatDate(organization.createdDate, 'MMM. D, YYYY') : '-'}</TableCell>
                                    {/* <TableCell size='small' align="right"><button onClick={(event) => handleClick(event, organization)}><MoreVertOutlined /></button></TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose} >
                    <MenuItem onClick={handleCreateOrg}>Edit</MenuItem>
                </Menu>
                <CreateOrganization selectedOrganization={selectedOrganization} open={createOrg} close={handleCreateOrg} refresh={getOrganizations} />
            </div>
        </Dashboard>
    )
}

export default Organization