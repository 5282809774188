import { IOrganization } from "../../../helpers/Interfaces";
import ApiService from "../../../services/ApiService";
import { updateOrganizations } from "../../../store/slices/organizationsSlice";

export type FormData = {
    name?: string;
    description?: string;
    registeredNumber?: string;
    language?: string;
    assetOwner?: string
};

export type FormDataError = {
    name?: boolean;
    description?: boolean;
    registeredNumber?: boolean;
    language?: boolean;
    assetOwner?: boolean
};

export class OrganizationFunction {
    apiService = new ApiService();

    createOrganization = (formData: FormData, formDataError: FormDataError,
        setFormDataError: (errors: FormDataError) => void, setLoading: (val: boolean) => void,
        handleFetchData: () => void, tenantId?: string,) => () => {
            const requiredFields: (keyof FormData)[] = ['name', 'language'];
            let hasError = false;
            const updatedErrors = requiredFields.reduce((errors, field) => {
                if (!formData[field]) {
                    errors[field] = true;
                    hasError = true;
                } else {
                    errors[field] = false;
                }
                return errors;
            }, {} as FormDataError);

            setFormDataError({ ...formDataError, ...updatedErrors });

            if (hasError) return;

            setLoading(true);

            if (tenantId) {
                return this.apiService.updateOrganization({
                    tenantId, name: formData.name, description: formData.description,
                    registeredNumber: formData.registeredNumber, assetOwner: formData.assetOwner, language: formData.language
                }).then((response) => {
                    if (response.status === 200) handleFetchData();
                }).catch((error) => {
                    console.error(`Error occur while updating Org ${error}`);
                }).finally(() => {
                    setLoading(false);
                })
            }

            return this.apiService.createOrganization({
                name: formData.name, description: formData.description,
                registeredNumber: formData.registeredNumber, assetOwner: formData.assetOwner, language: formData.language
            }).then((response) => {
                if (response.status === 200) handleFetchData();
            }).catch((error) => {
                console.error(`Error occur while creating Org ${error}`);
            }).finally(() => {
                setLoading(false);
            })
        };

    getAllOrganizations = (dispatch: any, setLoading: (val: boolean) => void) => {
        setLoading(true)
        this.apiService.getAllOrganization().then((response) => {
            const data = response.data.tenants as IOrganization[];

            dispatch(updateOrganizations(data));
        }).catch((error) => {
            console.error(`Error occur while fetching Org ${error}`);
        }).finally(() => {
            setLoading(false)
        })
    }
}