import { Autocomplete, Chip, TextField } from "@mui/material";
import { Search, Close } from "@mui/icons-material";
import { DropdownItemProps } from "../CustomAutocomplete/CustomAutocomplete";
import "./CustomMultiSelect.scss";
import "../MuiBaseStyle.scss";
import { t } from "i18next";

export default function CustomMultiSelect(props: {
    error?: boolean;
    helperText?: string;
    options: DropdownItemProps[];
    disabledOptions?: any[];
    label: string;
    onSelect: (value: DropdownItemProps[] | undefined) => void;
    defaultValue?: DropdownItemProps[];
    required?: boolean;
}) {
    const getOptions = () => {
        return props.options.map((item) => ({ ...item, label: t(item.label) }));
    };

    const getDefaultValues = () => {
        if (!props.defaultValue) return undefined;
        return props.defaultValue.map((item) => ({ ...item, label: t(item.label) }));
    };

    return (
        <Autocomplete
            multiple
            size="small"
            popupIcon={<Search />}
            fullWidth
            classes={{ root: "custom-mui-field custom-multi-select" }}
            defaultValue={getDefaultValues()}
            onChange={(event, value) => props.onSelect(value ?? undefined)}
            sx={{
                "& .MuiAutocomplete-popupIndicator": { transform: "none" },
            }}
            getOptionLabel={(option) => option.label || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        deleteIcon={<Close />}
                        label={option.label}
                        size="small"
                        {...getTagProps({ index })}
                    />
                ))
            }
            getOptionDisabled={(option) => props.disabledOptions?.includes(option.id) ?? false}
            options={getOptions()}
            renderInput={(params) => (
                <TextField
                    required={props.required}
                    {...params}
                    label={props.label}
                    error={props.error}
                    helperText={props.error ? props.helperText : null}
                />
            )}
        />
    );
}
