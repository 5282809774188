import { useCallback, useEffect, useState } from 'react'
import "./Users.scss"
import SecondaryButton from '../../../components/SecondaryButton/SecondaryButton';
import PluseSvg from '../../../assets/images/buttons/PluseSvg';
import Dashboard from '../../../components/Dashboard/Dashboard';
import CreateOrganization from '../../../components/CreateOrganization/CreateOrganization';
import { IListUser, IOrganization } from '../../../helpers/Interfaces';
import Paper from '@mui/material/Paper';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Menu, MenuItem, Avatar, Badge, Box } from '@mui/material';
import { formatDate } from '../../../helpers/helperfunctions';
import { MoreVertOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { UsersFunction } from './UsersFunction';
import CreateUser from '../../../components/createUser/createUser';

const Users = () => {
    const [view, setView] = useState<'organization' | 'user'>('organization');
    const [createUser, setCreateUser] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const functions = new UsersFunction();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedUser, setSelectedUser] = useState<IListUser | undefined>();
    const userList = useSelector((state: RootState) => state.userList);
    const dispatch = useDispatch<AppDispatch>();

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, selectedUser: IListUser) => {
        setSelectedUser(selectedUser);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getUsers = useCallback(
        () => functions.getAllUsers(dispatch, setLoading), [],)


    const handleCreateOrg = () => {
        handleClose();
        if (createUser) setSelectedUser(undefined);
        setCreateUser((prev: boolean) => !prev);
    }

    const getOrganizationList = (list?: { id: string; name: string, invited: boolean }[]) => {
        if (!list) return '-';

        return list.map((org) => <div style={{ textTransform: 'capitalize', display: 'flex', gap: '8px', alignItems: 'center' }}><Box
            sx={{
                width: 5,
                height: 5,
                borderRadius: 50,
                bgcolor: org.invited ? 'red' : 'green'
            }}
        />{org.name}
        </div>);
    }



    return (
        <Dashboard headerTitle={"Users"}>
            <div className='organization-container'>
                <div className="content-top">
                    <div className="lft">

                    </div>
                    <div className="rht">
                        <div className="content-right">
                            {/* <div className="filter-outer">
                                <span>
                                    {false ? <UserFilterRemove /> : <UserFilter />}
                                </span>
                                <span>
                                    {false ? <FilterIconRemove /> : <FilterIcon />}
                                </span>
                            </div> */}
                            <SecondaryButton label="Add New" icon={<PluseSvg />} onClick={handleCreateOrg} />
                        </div>
                    </div>
                </div>
                <TableContainer sx={{ marginTop: 3 }} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"></TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Created Date</TableCell>
                                <TableCell align="left">Roles</TableCell>
                                <TableCell align="left">Organizations</TableCell>
                                {/* <TableCell size='small' align="right"></TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userList.map((user) => (
                                <TableRow
                                    key={user.email}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">
                                        <Avatar sizes='small' alt={user.name} src={user.photoUrl} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {user.name}
                                    </TableCell>
                                    <TableCell align="left">{user.email}</TableCell>
                                    <TableCell align="left">{user.createdDate ? formatDate(user.createdDate, 'MMM. D, YYYY') : '-'}</TableCell>
                                    <TableCell align="left">{user.tenants ? user.tenants.map((tenant)=>(tenant.roles)) : '-'}</TableCell>
                                    <TableCell align="left">{getOrganizationList(user.tenants)}</TableCell>
                                    {/* <TableCell size='small' align="right"><button onClick={(event) => handleClick(event, user)}><MoreVertOutlined /></button></TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose} >
                    <MenuItem onClick={handleCreateOrg}>Edit</MenuItem>
                </Menu>
                <CreateUser selectedUser={selectedUser} open={createUser} close={handleCreateOrg} refresh={getUsers} />
            </div>
        </Dashboard>
    )
}

export default Users