import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import './CustomSelect.scss'
import '../MuiBaseStyle.scss'
import { DropdownItemProps } from "../CustomAutocomplete/CustomAutocomplete";
import { t } from "i18next";

export function CustomSelect(props: {
    error?: boolean, require?: boolean, helperText?: string, label: string, options: DropdownItemProps[],
    onSelect: (value: DropdownItemProps) => void, defaultValue?: DropdownItemProps, disabled?: boolean
}) {

    const getOptions = () => {
        const list = props.options.map(item => ({ ...item, label: t(item.label) }));
        return list;
    }

    return (
        <FormControl disabled={props.disabled ?? false} required={props.require} error={props.error} classes={{ root: 'custom-mui-field custom-select-field' }} fullWidth size="small">
            <InputLabel>{props.label}</InputLabel>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={props.defaultValue?.id}
                label={props.label}
                onChange={(event: any, value: any) => props.onSelect({ label: value.props.children, id: value.props.value } as DropdownItemProps)}
            >
                {getOptions().map(option => (<MenuItem key={option.label} value={option.id.toString()}>{option.label}</MenuItem>))}
            </Select>
            <FormHelperText>{!props.error ? props.helperText : "Required"}</FormHelperText>
        </FormControl>
    );
}