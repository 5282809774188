import { Navigate, BrowserRouter as Router, useNavigate, useRoutes } from "react-router-dom";
import logoImg from "./assets/images/logo.svg";
import "./App.scss";
import "./Master.scss";
import NotImplemented from "./pages/NotImplemented/NotImplemented";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from "./store/store";
import Builder from "./pages/Dashboard/Builder/Builder";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Libraries, LoadScript } from "@react-google-maps/api";
import CircularProgress from "@mui/material/CircularProgress";
import { googleApiKey } from "./helpers/helperfunctions";
import { getFromLocalStorage } from "./helpers/storageConfig";
import Library from "./pages/Dashboard/Library/Library";
import Organization from "./pages/Dashboard/Organization/Organization";
import Users from "./pages/Dashboard/Users/Users";
import { UsersFunction } from "./pages/Dashboard/Users/UsersFunction";
import { OrganizationFunction } from "./pages/Dashboard/Organization/OrganizationFunction";

const AppRoutes = ({ loggedIn, loading }: { loggedIn: boolean, loading: boolean }) => {
  return useRoutes((loading)
    ? ([
      { path: "/", element: (<Loading />) },
    ])
    : ([
      { path: "/", element: loggedIn ? (<Navigate to={"/dashboard/library-templates"} />) : (<Login />) },
      { path: "/login", element: loggedIn ? (<Navigate to={"/dashboard/library-templates"} />) : (<Login />) },
      { path: "/dashboard/organization", element: loggedIn ? (<Organization />) : (<Navigate to={"/"} />) },
      { path: '/forgot-password', element: (<ForgotPassword />) },
      { path: "/dashboard/builder", element: loggedIn ? (<Builder />) : (<Navigate to={"/"} />) },
      { path: "/dashboard/users", element: loggedIn ? (<Users />) : (<Navigate to={"/"} />) },
      { path: "/dashboard/library-templates", element: loggedIn ? (<Library />) : (<Navigate to={"/"} />) },
      { path: "*", element: loggedIn ? (<NotImplemented />) : <Navigate to={"/"} /> },
    ]));
}

function App() {
  const { i18n } = useTranslation();
  const [loggedIn, setLoggedIn] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const libraries: Libraries = ['places'];
  const dispatch = useDispatch<AppDispatch>();
  const userFunctions = new UsersFunction();
  const orgFunctions = new OrganizationFunction();



  useEffect(() => {
    validateLogin();
    getUsers();
    getOrganizations();
  }, [user])

  const getUsers = useCallback(
    () => userFunctions.getAllUsers(dispatch, setLoading), [],)

  const getOrganizations = useCallback(
    () => orgFunctions.getAllOrganizations(dispatch, setLoading), [],)

  const validateLogin = async () => {
    const customToken = getFromLocalStorage('firebaseCustomToken');
    i18n.changeLanguage(user.language);
    if (user.displayName) {
      return setLoggedIn(true);
    }
    return setLoggedIn(false);
  }

  return (
    <>
      <section className="small-screen-message">
        <div className="txt">
          <img src={logoImg} className="logo" alt="logo" />
          <h4>{t('screenWidthHeader')}</h4>
          <p>{t('screenWidthDescription')}</p>
        </div>
      </section>
      <main className="main">
        <LoadScript googleMapsApiKey={googleApiKey()} libraries={libraries} loadingElement={<Loading />}>
          <Router>
            <AppRoutes loading={loading} loggedIn={loggedIn} />
          </Router>
        </LoadScript>

      </main>
    </>
  );
}

export default App;

const Loading = () => {
  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress color='inherit' size={'20px'} />
    </div>
  )
}