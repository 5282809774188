import { StoreType } from "polotno/model/store";
import GoogleSearchBox from "../../../../../components/GoogleSearchBox/GoogleSearchBox";
import { t } from 'i18next';
import { LatLng } from "../../../../../helpers/Interfaces";
import { useEffect, useRef, useState } from "react";
import "./BuilderMap.scss";
import { ArrowDropDown, ArrowDropUp, Close, LocationOnSharp } from "@mui/icons-material";
import SecondaryButton from "../../../../../components/SecondaryButton/SecondaryButton";
import { generateUniqueKey, googleApiKey } from "../../../../../helpers/helperfunctions";
import { BuilderMapFunctions } from "./BuilderMapFunctions";

const BuilderMap = ({ store }: { store: StoreType }) => {
    const [destinations, setDestinations] = useState<LatLng[]>([]);
    const initialCenter = { lat: 37.7749, lng: -122.4194 };
    const mapRef = useRef<google.maps.Map | null>(null);
    const directionsServiceRef = useRef<google.maps.DirectionsService | null>(null);
    const directionsRendererRef = useRef<google.maps.DirectionsRenderer | null>(null);
    const customMarkersRef = useRef<google.maps.Marker[]>([]);
    const [mapUrl, setMapUrl] = useState<string>("");
    const mapFunctions = new BuilderMapFunctions();
    const [addImageLoading, setAddImageLoading] = useState<boolean>(false);

    useEffect(() => {
        initMap();
    }, []);

    useEffect(() => {
        if (destinations.length > 1) {
            mapFunctions.calculateRoute({
                directionsRendererRef, directionsServiceRef, destinations,
                setMapUrl, mapRef, customMarkersRef
            });
        }
    }, [destinations]);

    function initMap(): void {
        const map = new google.maps.Map(
            document.getElementById("google-map") as HTMLElement,
            {
                zoom: 4,
                center: initialCenter,
                disableDefaultUI: true,
                // zoomControl: true
            }
        );
        mapRef.current = map;
        directionsServiceRef.current = new google.maps.DirectionsService();
        directionsRendererRef.current = new google.maps.DirectionsRenderer({
            suppressMarkers: true, // Hide default markers
            polylineOptions: {
                strokeColor: "#134B5F", // Set your desired color here (e.g., red)
                strokeOpacity: 0.8,
                strokeWeight: 6,
            },
        });
        directionsRendererRef.current.setMap(map);
    }

    const removeLocation = (latLng: LatLng) => {
        setDestinations(prevDestinations =>
            prevDestinations.filter(
                location => location.lat !== latLng.lat || location.lng !== latLng.lng
            )
        );
    };

    const handleCaptureMapImage = async () => {
        if (!mapRef.current) return;
        setAddImageLoading(true);
        const center = {
            lat: mapRef.current.getCenter()?.lat(),
            lng: mapRef.current.getCenter()?.lng(),
        };
        const zoom = mapRef.current.getZoom() || 10;

        const pathCoordinates = destinations.map(dest => ({
            lat: dest.lat,
            lng: dest.lng,
        }));

        const apiKey = googleApiKey();

        const size = "800x600";

        const imageUrl = await mapFunctions.getMapImage({ center: center as any, zoom, size, encodedPath: mapUrl as any, pathCoordinates, apiKey });

        if (!store.activePage) return;
        store.activePage.addElement({
            type: 'image',
            src: imageUrl,
            width: 800,
            height: 600,
        });
        setAddImageLoading(false);
    };

    const moveUpItem = (item: LatLng) => {
        const _destinations = [...destinations];
        const index = _destinations.indexOf(item);
        if (index === 0) return;
        _destinations.splice(index, 1);
        _destinations.splice((index - 1), 0, item);
        setDestinations(_destinations);
    }

    const moveDownItem = (item: LatLng) => {
        const _destinations = [...destinations];
        const index = _destinations.indexOf(item);
        if (index === _destinations.length) return;
        _destinations.splice(index, 1);
        _destinations.splice((index + 1), 0, item);
        setDestinations(_destinations);
    }

    return (
        <div className="builder-map-container">
            <GoogleSearchBox
                size="small"
                required
                helperText={t('fieldRequired')}
                label={"Select Location"}
                onSelect={(place: any) => {
                    if (!place || !place.geometry || !place.geometry.location) return;
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    const newLocation = { id: generateUniqueKey(), lat, lng, location: place.formatted_address };
                    setDestinations(prev => [...prev, newLocation]);
                }}
            />

            <div className="location-list">
                {destinations.map((locationData, index) => (
                    <div className="selected-location" key={index}>
                        <div className="selected-location-inner">
                            <LocationOnSharp style={{ fontSize: '16px' }} />
                            {locationData.location}
                        </div>
                        <div className="controls">
                            <button onClick={() => moveUpItem(locationData)}><ArrowDropUp /></button>
                            <button onClick={() => moveDownItem(locationData)}><ArrowDropDown /></button>
                        </div>
                        <button onClick={() => removeLocation(locationData)}>
                            <Close style={{ fontSize: '16px' }} />
                        </button>
                    </div>
                ))}
            </div>

            <div className="image-container">
                <div style={{ height: '100%', width: '100%' }} id="google-map"></div>
            </div>

            <div className="footer-outer">
                <SecondaryButton loading={addImageLoading} style={{ width: 'fit-content' }} onClick={handleCaptureMapImage} label={"Capture Image"} />
            </div>
        </div>
    );
};

export default BuilderMap;
