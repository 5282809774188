import axios from "axios";
import { getBaseURL } from "../helpers/helperfunctions";
import AuthService from "./AuthService";
import { getFromLocalStorage, getTokens, saveTokens } from "../helpers/storageConfig";
import FirebaseFunctions from "../helpers/firebaseFunctions";

export default class ApiService {

    async getItineraryPages(data?: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/get-all-pages", data);
    }

    async getLeadViewItineraries(data: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/get-lead-itineraries", data);
    }

    async getTemplates(data?: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/get-all-templates", data);
    }

    async deleteItineraryDocument(data: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/delete-template", data);
    }

    async getItineraryImage(data: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/map-image", data);
    }

    async createOrganization(data: any) {
        const ax = await this.ax();
        return await ax.post("/organization/create", data);
    }

    async inviteUser(data: any) {
        const ax = await this.ax();
        return await ax.post("/user/invite-user", data);
    }

    async createTemplate(data?: any) {
        const ax = await this.ax();
        return await ax.post("/itinerary/create-template", data);
    }

    async updateInvitedUser(data: any) {
        const ax = await this.ax();
        return await ax.post("/user/update-invited-user", data);
    }

    async updateOrganization(data: any) {
        const ax = await this.ax();
        return await ax.post("/organization/update", data);
    }

    async getAllOrganization() {
        const ax = await this.ax();
        return await ax.get("/organization/getAll");
    }

    async getAllUsers() {
        const ax = await this.ax();
        return await ax.get("/user/get-all-users");
    }

    private async ax() {
        let tenantId = getFromLocalStorage('tenantId');

        const apiClient = axios.create({
            baseURL: getBaseURL()
        });

        apiClient.interceptors.request.use(async (config) => {
            const token = getTokens();

            config.headers.Authorization = `Bearer ${token}`;
            config.headers['x-tenant-id'] = tenantId;
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        apiClient.interceptors.response.use((response) => {
            return response;
        }, async (error) => {

            const originalRequest = error.config;
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                const refreshToken = getFromLocalStorage('refreshToken');


                const authService = new AuthService();
                const firebaseFunctions = new FirebaseFunctions();

                const response = await authService.refreshToken({ 'uid': refreshToken });
                const data = response.data;
                const token = data.tokens.token;
                const customToken = data.tokens.customToken;
                saveTokens(token);
                firebaseFunctions.authenticateUser(customToken);

                axios.defaults.headers.Authorization = `Bearer ${token}`;
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                originalRequest.headers['x-tenant-id'] = tenantId;
                return apiClient(originalRequest);
            }
            return Promise.reject(error);
        });
        return apiClient;
    }
}