import axios from "axios";
import { ILoginData } from "../helpers/Interfaces";
import { getBaseURL} from "../helpers/helperfunctions";
import { getFromLocalStorage } from "../helpers/storageConfig";

export default class AuthService {

    async userLogin(data: ILoginData) {
        const ax = await this.ax();
        return await ax.post("/user/admin-login", data);
    }
    
    async sendOtp(data:any){
        const ax = await this.ax();
        return await ax.post("/user/sendOtp",data);
    }

    async verifyOtp(data:any){
        const ax = await this.ax();
        return await ax.post("/user/confirmOtp",data);
    }

    async resetPassword(data:any){
        const ax = await this.ax();
        return await ax.post("/user/resetPassword",data)
    }

    async refreshToken(data: any) {
        const ax = await this.ax();
        return await ax.post("/user/refreshToken", data);
    }

    private async ax() {
        let baseUlr = getBaseURL();
        let tenantId = getFromLocalStorage('tenantId');

        return axios.create({
            baseURL: baseUlr,
            timeout: 60000,
            headers: {
                "Content-Type": "application/json",
                'x-tenant-id' : tenantId ?? ''
            }
        });
    }
}