import { DropdownItemProps } from "../components/Mui/CustomAutocomplete/CustomAutocomplete";

export const emptyTemplate = {
    "width": 1080,
    "height": 1080,
    "fonts": [],
    "pages": [
        {
            "id": "NKnZWopCS8",
            "children": [],
            "width": "auto",
            "height": "auto",
            "background": "white",
            "bleed": 0,
            "duration": 5000
        }
    ],
    "audios": [],
    "unit": "px",
    "dpi": 72
};

export const templateCategory: DropdownItemProps[] = [
    { label: 'Accommodation', id: 1 },
    { label: 'Activities', id: 2 },
    { label: 'Attraction', id: 3 },
    { label: 'Transport', id: 4 },
    { label: 'General', id: 5 },
];
