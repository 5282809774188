import { Modal } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import SecondaryButton from "../SecondaryButton/SecondaryButton";
import CustomTextField from "../Mui/CustomTextField/CustomTextField";
import "./createUser.scss";
import { IListUser } from "../../helpers/Interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { FormUserData, FormUserDataError, UsersFunction } from "../../pages/Dashboard/Users/UsersFunction";
import CustomMultiSelect from "../Mui/CustomMultiSelect/CustomMultiSelect";
import { DropdownItemProps } from "../Mui/CustomAutocomplete/CustomAutocomplete";
import { CustomSelect } from "../Mui/CustomSelect/CustomSelect";
import BorderlessButton from "../ButtonWithoutBorder/ButtonWithoutBorder";
import AddIcon from '@mui/icons-material/Add';

type CreateUserProps = {
  open: boolean;
  close: MouseEventHandler<HTMLButtonElement> | CallableFunction | any;
  selectedUser?: IListUser;
  refresh: () => void
};

const CreateUser = (props: CreateUserProps) => {
  const [formData, setFormData] = useState<FormUserData>({ firstName: '', email: '', lastName: '', tenants: [{ roles: [], id: '' }] });
  const [formDataError, setFormDataError] = useState<FormUserDataError>({ firstName: false, email: false, lastName: false, roles: false, tenants: false });
  const [loading, setLoading] = useState<boolean>(false);
  const userFunction = new UsersFunction();
  const organizations = useSelector((state: RootState) => state.organizations);

  const roleOptions: DropdownItemProps[] = [
    { id: 'admin', label: 'Admin' },
    { id: 'member', label: 'Member' },
  ];

  useEffect(() => {
    console.log(formData);
  }, [formData])

  useEffect(() => {
    if (props.selectedUser) {
      setFormData({
        firstName: props.selectedUser.name.split(' ')[0],
        lastName: props.selectedUser.name.split(' ')[1],
        email: props.selectedUser.email,
        tenants: props.selectedUser.tenants
      })
    }
  }, [props.selectedUser])

  const handleFetchData = () => {
    props.refresh();
    props.close();
    setFormData({ firstName: '', lastName: '', tenants: [], email: '' })
    setFormDataError({ firstName: false, email: false, lastName: false, roles: false, tenants: false })
  }

  const handleClose = () => {
    props.close();
    setFormData({ firstName: '', lastName: '', tenants: [], email: '' })
    setFormDataError({ firstName: false, lastName: false, roles: false, tenants: false, email: false })
  }

  const getOrganization = useCallback(
    () =>
      organizations.map((organization) => ({
        label: organization.name,
        id: organization.id,
      })) as DropdownItemProps[],
    [organizations]
  );

  const addTenant = () => {
    setFormData((prev) => ({
      ...prev,
      tenants: [...prev.tenants, { roles: [], tenantId: '' }],
    }));
  };

  const getDefaultRoles = useCallback(
    (tenantRoles: string[] = []): DropdownItemProps[] => {
      const filteredRoles = roleOptions.filter((role) => tenantRoles.includes(String(role.id)));
      return filteredRoles;
    },
    [roleOptions]
  );

  const getDefaultOrganization = useCallback((tenantId: string): DropdownItemProps | undefined => {
    const matchingOrganization = organizations.find(
      (organization) => organization.id === tenantId
    );


    return matchingOrganization
      ? {
        label: (matchingOrganization as any).label ?? '',
        id: matchingOrganization.id ?? '',
      }
      : undefined; // Return null if no matching organization is found
  }, [formData.tenants, organizations]);


  const handleCreateUser = () => {
    userFunction.createUser((props.selectedUser !== undefined), formData, setFormDataError, setLoading,
      handleFetchData, props.selectedUser?.email);
  }

  return (
    <Modal open={props.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="insert-image-container">
        <div className="header-outer">
          <span className="header-title">{props.selectedUser?.email ? "Update" : "Create"} User</span>
          <button onClick={() => {
            handleClose();
          }}><CloseSharp /></button>
        </div>
        <div className="form-content">
          <span className="org-list-title">Select a organization</span>
          <CustomTextField value={formData.firstName} error={formDataError.firstName} required size="small" label="First Name" onChange={(val) => {
            if (!val) return;
            setFormData({ ...formData, firstName: val.target.value });
          }} />
          <CustomTextField value={formData.lastName} error={formDataError.firstName} required size="small" label="Last Name" onChange={(val) => {
            if (!val) return;
            setFormData({ ...formData, lastName: val.target.value });
          }} />
          <CustomTextField value={formData.email} error={formDataError.email} required size="small" label="Email" onChange={(val) => {
            if (!val) return;
            setFormData({ ...formData, email: val.target.value });
          }} />
          <span className="org-list-title">Select a organization</span>

          {formData.tenants?.map((tenant, index) => (
            <div className="tenant-row">
              <div className="organization-select">
                <CustomSelect label={"Organization"} options={getOrganization()} onSelect={(value) => {
                  const updatedTenants = [...formData.tenants];
                  updatedTenants[index].id = value.id ? String(value.id) : undefined;
                  setFormData({ ...formData, tenants: updatedTenants });
                }} defaultValue={getDefaultOrganization(tenant.id!)} />
              </div>

              <CustomMultiSelect defaultValue={getDefaultRoles(tenant.roles)} options={roleOptions} label={'roles'} onSelect={(values) => {
                const roles = values?.map((val) => val.id.toString());
                const updatedTenants = [...formData.tenants];
                updatedTenants[index].roles = roles;
                setFormData({ ...formData, tenants: updatedTenants })
              }} />
            </div>
          ))}

          <BorderlessButton icon={<AddIcon />} onClick={addTenant} label={"Add Organization"} styles={{ padding: '0' }} />


        </div>
        <div className="footer-outer">
          <SecondaryButton loading={loading} onClick={handleCreateUser}
            label={props.selectedUser ? "Update" : "Create"} />
        </div>
      </div>
    </Modal>
  );
};

export default CreateUser;
