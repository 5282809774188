import { emit } from "process";
import { IListUser, IOrganization } from "../../../helpers/Interfaces";
import ApiService from "../../../services/ApiService";
import { updateUserList } from "../../../store/slices/listUeresSlice";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

export type FormUserData = {
    firstName?: string;
    lastName?: string;
    email?: string;
    tenants:{
        roles?: string[];
        id?: string;
    }[]
};

export type FormUserDataError = {
    firstName: boolean;
    lastName: boolean;
    email: boolean;
    roles: boolean;
    tenants: boolean;
};

export class UsersFunction {
    apiService = new ApiService();

    createUser = (edit: boolean, formData: FormUserData,
        setFormDataError: (errors: FormUserDataError) => void, setLoading: (val: boolean) => void,
        handleFetchData: () => void, tenantId?: string,) => {

        const requiredFields: (keyof FormUserData)[] = ['firstName', 'lastName', 'email'];
        let hasError = false;

        const updatedErrors: FormUserDataError = {
            firstName: false,
            lastName: false,
            email: false,
            roles: false,
            tenants: false,
        };

        // Validate each required field
        requiredFields.forEach((field) => {
            if (!formData[field] || (Array.isArray(formData[field]) && formData[field]?.length === 0)) {
                updatedErrors[field] = true;
                hasError = true;
            }
        });
        

        if (!formData.tenants || formData.tenants.length === 0) {
            updatedErrors.tenants = true;
            hasError = true;
        } else {
            formData.tenants.forEach((tenant, index) => {
                if (!tenant.id || !tenant.roles || tenant.roles.length === 0) {
                    updatedErrors.tenants = true;
                    hasError = true;
                    console.warn(`Validation failed for tenant at index ${index}`);
                }
            });
        }

        // Set the error state
        setFormDataError(updatedErrors);

        if (hasError) {
            console.warn("Validation failed:", updatedErrors);
            return;
        }

        setLoading(true);

        if (edit) {
            return this.apiService.updateInvitedUser({
                displayName: `${formData.firstName?.trim()} ${formData.lastName?.trim()}`,
                email: formData.email,
                tenants: formData.tenants
            }).then((response) => {
                if (response.status === 200) handleFetchData();
            }).catch((error) => {
                console.error(`Error occur while creating Org ${error}`);
            }).finally(() => {
                setLoading(false);
            })
        }

        return this.apiService.inviteUser({
            displayName: `${formData.firstName?.trim()} ${formData.lastName?.trim()}`,
            email: formData.email,
            tenants: formData.tenants
        }).then((response) => {
            if (response.status === 200) handleFetchData();
        }).catch((error) => {
            console.error(`Error occur while creating Org ${error}`);
        }).finally(() => {
            setLoading(false);
        })
    }

    getAllUsers = (dispatch: any, setLoading: (val: boolean) => void) => {
        setLoading(true)
        this.apiService.getAllUsers().then((response) => {
            const data = response.data.users as IListUser[];

            dispatch(updateUserList(data));
        }).catch((error) => {
            console.error(`Error occur while fetching Org ${error}`);
        }).finally(() => {
            setLoading(false)
        })
    }
}